
export default {
  computed: {
    rightBg() {
      return this.$i18n.locale === 'en'
        ? 'https://images.projectcarmen.com/resource/ads/64dcfc51579c509a80077dbc.jpeg'
        : this.$i18n.locale === 'es'
          ? 'https://images.projectcarmen.com/resource/ads/64dcfc51579c509a80077dbc.jpeg'
          : 'https://images.projectcarmen.com/resource/ads/64dcfc6b579c509a80077dbe.png'
    },
    centerImg() {
      return this.$i18n.locale === 'en'
        ? 'https://images.projectcarmen.com/resource/ads/64dcfbb2904fcf7d1405f45d.png'
        : this.$i18n.locale === 'es'
          ? 'https://images.projectcarmen.com/resource/ads/64dcfbc1eb53c9492402ebec.png'
          : 'https://images.projectcarmen.com/resource/ads/64dcfbd2eb53c9492402ebee.png'
    },
  }
}
