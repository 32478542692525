
import BaseStoreIcons from '../base/BaseStoreIcons.vue';
export default {
  components: {
    BaseStoreIcons
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgSizes: {
        google: { width: '206', height: '62'},
        apple:  { width: '206', height: '62'},
        amazon:  { width: '206', height: '62'}
      }
    }
  }
}
