
import HomeLeft from './HomeLeft.vue'
import HomeRight from './HomeRight.vue';
export default {
  components: {
    HomeLeft,
    HomeRight
  },
  computed: {
    leftImg() {
      return this.$i18n.locale === 'en'
        ? 'https://images.projectcarmen.com/resource/ads/64dcfb3b904fcf7d1405f452.png'
        : this.$i18n.locale === 'es' 
        ? 'https://images.projectcarmen.com/resource/ads/64dcfb58eb53c9492402ebe5.png'
        : 'https://images.projectcarmen.com/resource/ads/64dcfb69eb53c9492402ebe7.png'
    }
  }
}
